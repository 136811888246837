import React, { useContext, lazy, Suspense, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import {
  FINANCE,
  HOME,
  LOGIN,
  ORDERS,
  ORDER_DETAIL,
  REGISTER,
  RESET_PASSWORD,
  TRACKING_ORDER,
  USER_DETAIL,
  VERIFY_EMAIL,
  SUBSCRIPTION,
  CREATOR,
  PAYMENT
} from 'settings/constants';

import AuthProvider, { AuthContext } from 'context/auth';
import { InLineLoader } from 'components/InlineLoader/InlineLoader';
import Register from 'containers/Login/Register';
import Finance from 'containers/Finance';
import Subscription from 'containers/Subscription';

const AdminLayoutV2 = lazy(() => import('containers/Layout/LayoutV2'));
const Home = lazy(() => import('containers/Home'));
const AdminLayout = lazy(() => import('containers/Layout/Layout'));
const NoAuthLayout = lazy(() => import('containers/Layout/NoAuthLayout'));
const Orders = lazy(() => import('containers/Orders/Orders'));
const OrderDetail = lazy(() => import('containers/Orders/OrderDetail'));
const UserDetails = lazy(() => import('containers/UserDetails/UserDetails'));
const Login = lazy(() => import('containers/Login/Login'));
const VerifyEmail = lazy(() => import('containers/VerifyEmail/VerifyEmail'));
const ResetPassword = lazy(() => import('containers/ResetPassword/ResetPassword'));
const TrackingOrder = lazy(() => import('containers/TrackingOrder/TrackingOrder'));
const Creator = lazy(() => import('containers/Creator'));
const NotFound = lazy(() => import('containers/NotFound/NotFound'));

/**
 * Higher-order component to wrap a route with Suspense.
 */
const SuspenseWrapper = ({ children }) => (
  <Suspense fallback={<InLineLoader />}>{children}</Suspense>
);

/**
 * A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
 */
function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === '/') {
      history.push(HOME);
    }
  }, [location.pathname, history]);

  return (
    <AuthProvider>
      <SuspenseWrapper>
        <Switch>
          <PrivateRoute path={ORDERS}>
            <AdminLayout>
              <SuspenseWrapper>
                <Orders />
              </SuspenseWrapper>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={`${ORDER_DETAIL}/:orderId`}>
            <AdminLayout>
              <SuspenseWrapper>
                <OrderDetail />
              </SuspenseWrapper>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={FINANCE}>
            <AdminLayoutV2>
              <SuspenseWrapper>
                <Finance />
              </SuspenseWrapper>
            </AdminLayoutV2>
          </PrivateRoute>

          <PrivateRoute path={SUBSCRIPTION}>
            <AdminLayoutV2>
              <SuspenseWrapper>
                <Subscription />
              </SuspenseWrapper>
            </AdminLayoutV2>
          </PrivateRoute>

          <PrivateRoute path={USER_DETAIL}>
            <AdminLayout>
              <SuspenseWrapper>
                <UserDetails />
              </SuspenseWrapper>
            </AdminLayout>
          </PrivateRoute>

          <Route path={HOME}>
            <SuspenseWrapper>
              <Home />
            </SuspenseWrapper>
          </Route>

          <Route path={REGISTER}>
            <NoAuthLayout>
              <SuspenseWrapper>
                <Register />
              </SuspenseWrapper>
            </NoAuthLayout>
          </Route>

          <Route path={LOGIN}>
            <NoAuthLayout>
              <SuspenseWrapper>
                <Login />
              </SuspenseWrapper>
            </NoAuthLayout>
          </Route>

          <Route path={RESET_PASSWORD}>
            <NoAuthLayout>
              <SuspenseWrapper>
                <ResetPassword />
              </SuspenseWrapper>
            </NoAuthLayout>
          </Route>

          <Route path={VERIFY_EMAIL}>
            <NoAuthLayout>
              <SuspenseWrapper>
                <VerifyEmail />
              </SuspenseWrapper>
            </NoAuthLayout>
          </Route>

          <Route path={TRACKING_ORDER}>
            <NoAuthLayout>
              <SuspenseWrapper>
                <TrackingOrder />
              </SuspenseWrapper>
            </NoAuthLayout>
          </Route>

          <Route path={CREATOR}>
            <NoAuthLayout>
              <SuspenseWrapper>
                <Creator />
              </SuspenseWrapper>
            </NoAuthLayout>
          </Route>

          <Route component={NotFound} />
        </Switch>
      </SuspenseWrapper>
    </AuthProvider>
  );
};

export default Routes;
